import { AppCampaignsSlideover, FilesSlideover } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppCampaignsSlideoverProps = ComponentProps<typeof AppCampaignsSlideover>

export type CampaignsUtils = ReturnType<typeof useCampaignsUtils>

// view:

export const VIEW_SETTINGS_CAMPAIGNS: ViewSettings<Campaign> = {
  tableName: 'campaigns',
  views: optionsShared.views.filter(view => ['table', 'cards', 'calendar'].includes(view.value)),
  selectedView: 'cards',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'dateStart',
      label: 'Fecha de Inicio',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'dateEnd',
      label: 'Fecha de Cierre',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'userId',
      label: 'Responsable',
    },
    {
      key: 'description',
      label: 'Descripción',
      class: 'min-w-[300px]',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
  groups: [
    { label: 'Tipo', value: 'type', options: optionsActivities.type },
    { label: 'Estado', value: 'status', options: optionsActivities.status },
  ],
  group: 'type',
}

export const useCampaigns = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Campaign>('campaigns')

  const slideoverOpenCreate = (props: AppCampaignsSlideoverProps) => {
    return new Promise<Campaign>((resolve, reject) => {
      const data = getDefaultCampaign(props.data)
      slideover.open(AppCampaignsSlideover, {
        title: 'Crear campaña',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppCampaignsSlideoverProps) => {
    return new Promise<Campaign>((resolve, reject) => {
      const data = getDefaultCampaign(props.data)
      slideover.open(AppCampaignsSlideover, {
        title: 'Editar campaña',
        ...props,
        disabledFields: ['type'],
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    type: z.string(),
    status: z.string(),
    name: z.string(),
    dateStart: z.string(),
    dateEnd: z.string(),
    userId: z.string(),
    description: z.string(),
  }).required()

  // actions:

  const getDropdownItems = (row: Campaign, refresh: () => Promise<void>, utils: CampaignsUtils, dropdownItemsAction: DropdownItem[][] = []): DropdownItem[][] => [
    [
      {
        label: row.$inmutable ? 'Ver detalles' : 'Editar',
        icon: row.$inmutable ? 'i-mdi-eye' : 'i-mdi-pencil',
        click: () => {
          slideoverOpenEdit({
            data: row,
            disabledFields: [],
            onSubmit: async (event) => {
              await onSubmitUpdate(event.data)
              slideover.close()
              await refresh()
            },
          })
        },
      },
      {
        label: 'Duplicar',
        icon: 'i-mdi-content-duplicate',
        click: async () => {
          const { id, ...data } = row
          await onSubmitCreate(data)
          await refresh()
        },
      },
      {
        label: 'Copiar ID',
        icon: 'i-mdi-content-copy',
        click: () => { copyToClipboard(row.id) },
      },
      {
        label: row.deletedAt ? 'Desarchivar' : 'Archivar',
        icon: row.deletedAt ? 'i-mdi-archive-arrow-up' : 'i-mdi-archive-arrow-down',
        click: async () => {
          const deletedAt = row?.deletedAt ? null : new Date().toISOString()
          await onSubmitUpdate({ ...row, deletedAt }) // soft delete
          await refresh()
        },
      },
    ],
    [
      {
        label: 'Abrir notas',
        icon: 'i-mdi-text',
        click: () => {
          const notes$ = useNotes()
          notes$.slideoverOpen({ data: { entityId: row.id, entity: 'campaigns', text: '' } })
        },
      },
    ],
    [
      {
        label: 'Ver archivos',
        icon: 'i-mdi-file-multiple',
        click: () => {
          slideover.open(FilesSlideover, { data: row, refresh, title: 'Ver archivos', utils })
        },
      },
    ],
    ...dropdownItemsAction,
    [
      {
        label: 'Información',
        icon: 'i-mdi-information',
        click: () => { slideoverOpenInformation('campaigns', row) },
      },
      {
        label: row.$inmutable ? 'Desbloquear' : 'Bloquear',
        icon: row.$inmutable ? 'i-mdi-lock-open-variant' : 'i-mdi-lock',
        click: async () => {
          await onSubmitUpdate({ ...row, $inmutable: !row.$inmutable })
          await refresh()
        },
      },
    ],
    [
      {
        label: 'Eliminar',
        icon: 'i-mdi-delete',
        iconClass: 'bg-red-500',
        labelClass: 'text-red-500',
        click: () => {
          modalOpenDelete({
            onSubmit: async (event) => {
              await onSubmitDelete([row])
              modal.close()
              await refresh()
            },
          })
        },
      },
    ],
  ]

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useCampaignsUtils = ({ $users }: {
  $users?: Awaited<ReturnType<typeof useFetch<User[]>>>
} = {}) => {
  const options = {
    ...optionsCampaigns,
    userId: computed(() => $users.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsCampaigns.type, 'value'),
    status: toMapByKey(optionsCampaigns.status, 'value'),
    userId: computed(() => toMapByKey($users.data.value, 'id')),
  }

  return { options, maps }
}
