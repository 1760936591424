<script setup lang="ts">
import type { AccordionItem } from '#ui/types'
import { format, parseISO } from 'date-fns'

const props = defineProps<{
  data: Record<string, unknown>
  entityId: Auditlog['entityId']
  entity: Auditlog['entity']
}>()

// matches AuditlogsQuery
const query = ref({
  action: [],
  entityId: [props.entityId],
  entity: [props.entity],
  userId: [],
  deletedAt: '$isNull',
  $search: { columns: ['text'], value: '' },
  $sort: { column: 'createdAt', direction: 'desc' },
  // $pagination: { page: 1, pageSize: 25 },
  $with: { user: true },
})
const $auditlogs = await useFetch<Auditlog[]>('/api/db/auditlogs', {
  query,
  default: () => [],
})

const {
  onSubmitCreate,
  onSubmitUpdate,
  onSubmitUpdateMultiple,
  modal,
  modalOpenChanges,
  schema,
  getDropdownItems,
  getAuditlogDescription,
} = useAuditlogs()

const $users = await useFetch<User[]>('/api/db/users', {
  query: { $columns: ['id', 'name', 'image'] },
  default: () => [],
})

const utils = useAuditlogsUtils({ $users })

const state = ref<Data>(props.data)

const tabItems = computed(() => [
  { value: 'details', label: 'Detalles', disabled: false },
  { value: 'modifications', label: 'Modificaciones', disabled: false },
])

const selectedTab = ref<number>(0)

const items = computed<AccordionItem[]>(() => [
  {
    slot: 'general',
    label: 'Información general',
    icon: 'i-mdi-information',
    defaultOpen: true,
  },
])
</script>

<template>
  <UDashboardSlideover :title="`Registro: ${state.name ?? state.uid ?? state.id}`">
    <UTabs v-model="selectedTab" :items="tabItems" />

    <template v-if="selectedTab === 0">
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <UFormGroup
            label="ID del Recurso"
            name="id"
          >
            <InputReadOnly :model-value="state.id" />
          </UFormGroup>

          <UFormGroup
            label="Creado por"
            name="createdBy"
          >
            <InputReadOnly :model-value="utils.maps.userId!.value.get(state.createdBy)!.name!" />
          </UFormGroup>

          <UFormGroup
            label="Creado en"
            name="createdAt"
          >
            <InputReadOnly :model-value="format(parseISO(state.createdAt), 'yyyy-MM-dd HH:mm:ss')" />
          </UFormGroup>

          <UFormGroup
            label="Actualizado por"
            name="updatedBy"
          >
            <InputReadOnly :model-value="utils.maps.userId!.value.get(state.updatedBy)!.name!" />
          </UFormGroup>

          <UFormGroup
            label="Actualizado en"
            name="updatedAt"
          >
            <InputReadOnly :model-value="format(parseISO(state.updatedAt), 'yyyy-MM-dd HH:mm:ss')" />
          </UFormGroup>
        </template>
      </UAccordion>
    </template>

    <template v-else-if="selectedTab === 1">
      <ViewPlaceholder
        view="cards"
        :loading="['pending'].includes($auditlogs.status.value)"
        :rows="$auditlogs.data.value"
        icon="i-mdi-history"
        label="No hay registros de auditoría para mostrar."
      >
        <div class="flex-1 h-full overflow-y-auto flex flex-col gap-3">
          <AppAuditlogsCardAuditlog
            v-for="auditlog in $auditlogs.data.value"
            :key="`auditlog-${auditlog.id}`"
            :auditlog="auditlog"
            :utils="utils"
            :dropdown-items="getDropdownItems(auditlog, $auditlogs.refresh, utils)"
          />
        </div>
      </ViewPlaceholder>
    </template>
  </UDashboardSlideover>
</template>
