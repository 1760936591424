<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types';

const props = defineProps<{
  data: Partial<Salesorder>
  onSubmit: (event: FormSubmitEvent<Partial<Salesorder>>) => void
  title?: string
  disabledFields?: (keyof Salesorder | string)[]
  readonly?: boolean
}>()

const router = useRouter()
const { user } = useUserSession()
const { moduleId, userModule } = useModules()

const inert = computed(() => props.readonly || props.data?.$inmutable || !['admin', 'edit'].includes(userModule.value?.role))

const state = ref<Partial<Salesorder>>(props.data)

const {
  onSubmitCreate,
  onSubmitUpdate,
  onSubmitDelete,
  onSubmitUpdateMultiple,
  slideover,
  slideoverOpenCreate,
  slideoverOpenEdit,
  slideoverOpenInformation,
  slideoverOpenFilePreview,
  modal,
  modalOpenDelete,
  schema,
  createUidSalesorders,
  getDropdownItems,
  createPdf,
  createFileSalesorderPdf,
} = useSalesorders()

state.value.uid ||= createUidSalesorders(state.value)

const $accounts = await useFetch<Account[]>('/api/db/accounts', {
  query: { $columns: ['id', 'name', 'image', 'billing'] },
  default: () => [],
})
const $branches = await useFetch<Branch[]>('/api/db/branches', {
  default: () => [],
})
const $contacts = await useFetch<Contact[]>('/api/db/contacts', {
  query: { type: ['customer'] },
  default: () => [],
})
const $deals = await useFetch<Deal[]>('/api/db/deals', {
  query: { $columns: ['id', 'uid', 'image', 'accountId', 'branchId', 'userId'] },
  default: () => [],
})
const $quotes = await useFetch<Salesorder[]>('/api/db/quotes', {
  default: () => [],
})
const $users = await useFetch<User[]>('/api/db/users', {
  query: { $columns: ['id', 'name', 'image'] },
  default: () => [],
})

const utils = useSalesordersUtils({ $accounts, $branches, $contacts, $deals, $quotes, $users })

const deals$ = useDeals()
const quotes$ = useQuotes()

const items = computed<AccordionItem[]>(() => {
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: ICONS.salesorders,
      defaultOpen: true,
    },
    {
      slot: 'delivery',
      label: 'Entrega',
      icon: 'i-mdi-truck-delivery',
      defaultOpen: true,
    },
    {
      slot: 'payment',
      label: 'Facturación y condiciones de pago',
      icon: 'i-mdi-credit-card',
      defaultOpen: true,
    },
    {
      slot: 'notes',
      label: 'Notas',
      icon: 'i-mdi-text',
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Información adicional',
      icon: 'i-mdi-dots-horizontal',
      defaultOpen: true,
    },
    {
      slot: 'pdfSettings',
      label: 'Configuración del PDF',
      icon: 'i-mdi-file-pdf',
      defaultOpen: false,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const onUpdateAccountId = async (id: string) => {
  const account = utils.maps.accountId.value.get(id) as Account
  if (!account) return
  state.value.receiver = { ...state.value.receiver, ...account.billing }
  state.value.payment = { ...state.value.payment, ...account.payment }
}

const onUpdateBranchId = async (branchId: string) => {
  const branch = utils.maps.branchId.value.get(branchId) as Branch
  if (!branch) return
  state.value.delivery.address = branch.address
}

const onUpdateQuoteId = (quoteId: string) => {
  const quote = utils.maps.quoteId.value.get(quoteId) as Quote
  if (!quote || !quote.payment) return
  const {
    userId,
    issuer,
    receiver,
    delivery,
    accountId,
    branchId,
    contactId,
    ...quoteData
  } = quote

  const {
    options,
    ...paymentData
  } = quote.payment

  state.value.userId = userId
  state.value.payment = paymentData
  state.value.receiver = receiver
  state.value.issuer = issuer
  state.value.delivery = delivery
  state.value.accountId = accountId
  state.value.branchId = branchId
  state.value.contactId = contactId
}

const accountInformation = computed(() => {
  return [
    ['Razón social', state.value.receiver?.legalName],
    ['RFC', state.value.receiver?.taxId],
    ['Dirección de facturación', formatAddress(state.value.receiver?.address)],
    ['Regimen fiscal', utils.maps.billing.satFiscalRegime.get(state.value.receiver?.satFiscalRegime)?.label?.toUpperCase()],
    ['Uso del CFDI', utils.maps.satCfdiUse?.get(state.value.payment?.satCfdiUse)?.label?.toUpperCase()],
  ]
})

const contactInformation = computed(() => {
  const contact = utils.maps.contactId.value.get(state.value.contactId)
  if (!contact) return []
  return [
    ['Titulo', contact.title],
    ['Nombre', contact.name],
    ['Correo', contact.email],
    ['Teléfono', contact.phone],
  ]
})

const loading = ref(false)

const onSubmitSalesorder = async (event: FormSubmitEvent<Partial<Salesorder>>) => {
  loading.value = true
  event.data.uid ||= createUidSalesorders(event.data)
  await props.onSubmit(event)
  router.push(`/${moduleId.value}/salesorders/${event.data.id}/items`)
}
</script>

<template>
  <UDashboardSlideover prevent-close>
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.salesorders"
      :schema="schema"
      :validate-on="['submit']"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitSalesorder"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="optionsSalesorders.type"
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <UFormGroup
            label="Negocio"
            name="dealId"
            :required="isRequired(schema, 'dealId')"
          >
            <UButtonGroup class="flex">
              <SelectMenuBase
                v-model="state.dealId"
                :options="utils.options.dealId.value"
                :disabled="disabledFields?.includes('dealId')"
                class="flex-1"
              />
              <UButton
                icon="i-mdi-plus"
                @click="openNested(() => deals$.slideoverOpenCreate({
                  onSubmit: async (event) => {
                    const deal = await deals$.onSubmitCreate(event.data)
                    state.dealId = deal!.id
                    await $deals.refresh()
                    await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                  },
                }))"
              />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup
            label="Cotización"
            name="quoteId"
            :required="isRequired(schema, 'quoteId')"
          >
            <div class="flex gap-1">
              <UButtonGroup class="flex-1">
                <SelectMenuBase
                  v-model="state.quoteId"
                  :options="utils.maps.quoteByDealId.value.get(state.dealId)?.map(toOption) || []"
                  :disabled="disabledFields?.includes('quoteId') || !state.dealId"
                  class="flex-1"
                  @update:model-value="onUpdateQuoteId"
                />
                <UButton
                  icon="i-mdi-plus"
                  @click="openNested(() => deals$.slideoverOpenCreate({
                    onSubmit: async (event) => {
                      const deal = await deals$.onSubmitCreate(event.data)
                      state.dealId = deal!.id
                      await $deals.refresh()
                      await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                    },
                  }))"
                />
              </UButtonGroup>
              <ButtonRefresh
                toast="Campos relacionados a la cotización actualizada correctamente."
                :disabled="!state.quoteId"
                @click="onUpdateQuoteId(state.quoteId)"
              />
            </div>
          </UFormGroup>

          <UFormGroup
            label="Vendedor"
            name="userId"
            :required="isRequired(schema, 'userId')"
          >
            <SelectMenuBase
              v-model="state.userId"
              :options="utils.options.userId.value"
              :disabled="disabledFields?.includes('userId')"
            />
          </UFormGroup>

          <UFormGroup
            label="Cuenta"
            name="accountId"
            :required="isRequired(schema, 'accountId')"
          >
            <div class="flex gap-1">
              <UButtonGroup class="flex-1">
                <InputReadOnly
                  :model-value="utils.maps.accountId.value.get(state.accountId)?.name ?? 'Selecciona una cotización'"
                  class="flex-1"
                />
                <UButton icon="i-mdi-plus" disabled />
              </UButtonGroup>
              <ButtonRefresh
                toast="Información fiscal y opciones de pago de la cotización actualizados correctamente."
                :disabled="!state.accountId"
                @click="onUpdateAccountId(state.accountId)"
              />
            </div>
          </UFormGroup>

          <ViewRendererInformation
            title="Información de la orden de venta"
            :entries="accountInformation"
            :default-open="true"
          />
        </template>

        <template #details>
          <UFormGroup
            label="Etapa"
            name="stage"
            :required="isRequired(schema, 'stage')"
          >
            <SelectMenuBaseInfo
              v-model="state.stage"
              :options="optionsSalesorders.stage"
              :disabled="disabledFields?.includes('stage')"
            />
          </UFormGroup>

          <UFormGroup
            label="Estado"
            name="status"
            :required="isRequired(schema, 'status')"
          >
            <SelectMenuBaseInfo
              v-model="state.status"
              :options="optionsSalesorders.status"
              :disabled="disabledFields?.includes('status')"
            />
          </UFormGroup>

          <UFormGroup
            label="Fecha de recepción"
            name="date"
            :required="isRequired(schema, 'date')"
          >
            <InputDatetime
              v-model="state.date"
              :is-datetime="state.isDatetime"
              :disabled="disabledFields?.includes('date')"
            />
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              :creatable="true"
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              accept="*"
              :required="['salesorder', 'payslip'].includes(state.type)"
              :query="{ prefix: `salesorders/${state.id}/files` }"
            />
          </UFormGroup>

          <UFormGroup
            label="Detalles"
            name="text"
            :required="isRequired(schema, 'text')"
          >
            <UTextarea
              v-model="state.text"
              autoresize
              :disabled="disabledFields?.includes('text')"
            />
          </UFormGroup>
        </template>

        <template #delivery>
          <UFormGroup
            label="Términos de entrega"
            name="delivery.incoterm"
            :required="isRequired(schema, 'delivery.incoterm')"
          >
            <SelectMenuBaseInfo
              v-model="state.delivery.incoterm"
              :options="utils.options.delivery.incoterm"
              :disabled="disabledFields?.includes('delivery.incoterm')"
            />
          </UFormGroup>

          <UFormGroup
            label="Contacto"
            name="contactId"
            :required="isRequired(schema, 'contactId')"
            help="Contacto al cual se le enviarian notificaciones."
          >
            <UButtonGroup class="flex">
              <SelectMenuBase
                v-model="state.contactId"
                :options="utils.maps.contactsByAccountId.value.get(state.accountId)?.map(toOption) ?? []"
                :disabled="disabledFields?.includes('contactId') || !state.accountId"
                class="flex-1"
              />
              <UButton
                icon="i-mdi-plus"
                :disabled="disabledFields?.includes('contactId') || !state.accountId"
                @click="openNested(() => contacts$.slideoverOpenCreate({
                  data: { type: 'customer', accountId: state.accountId },
                  disabledFields: ['type', 'accountId'],
                  onSubmit: async (event) => {
                    const contact = await contacts$.onSubmitCreate(event.data)
                    state.contactId = contact!.id
                    await $contacts.refresh()
                    await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                  },
                }))"
              />
            </UButtonGroup>
          </UFormGroup>

          <ViewRendererInformation
            v-if="state.contactId"
            title="Información del contacto"
            :entries="contactInformation"
            :default-open="true"
          />

          <UFormGroup
            label="Fecha de entrega"
            name="delivery.date"
            :required="isRequired(schema, 'delivery.date')"
          >
            <InputDatetime
              v-model="state.delivery.date"
              :is-datetime="state.delivery.isDatetime"
              :disabled="disabledFields?.includes('delivery.date')"
            />
          </UFormGroup>

          <UFormGroup
            v-if="!['pending', 'EXW'].includes(state.delivery.incoterm)"
            label="Dirección de entrega"
            name="branchId"
            :ui="{ help: 'text-xs mt-1' }"
            :required="!state.delivery.useAccountAddress && !['EXW', 'pending'].includes(state.delivery.incoterm) || !state.accountId"
          >
            <div
              v-if="state.delivery.useAccountAddress"
              class="w-full flex items-center gap-1"
            >
              <UButton
                icon="i-mdi-checkbox-marked"
                square
                variant="outline"
                :ui="{ variant: { outline: 'ring-gray-300 dark:ring-gray-700' } }"
                :disabled="['EXW', 'pending'].includes(state.delivery.incoterm)"
                @click="() => state.delivery.useAccountAddress = !state.delivery.useAccountAddress || !state.accountId"
              />
              <UButtonGroup class="flex w-full pl-1">
                <InputReadOnly
                  model-value="Entregar en domicilio fiscal"
                  class="flex-1"
                />
                <UButton icon="i-mdi-plus" disabled />
              </UButtonGroup>
            </div>
            <UButtonGroup v-else class="w-full">
              <UButton
                icon="i-mdi-checkbox-blank-outline"
                square
                variant="outline"
                :ui="{ variant: { outline: 'ring-gray-300 dark:ring-gray-700' } }"
                :disabled="['EXW', 'pending'].includes(state.delivery.incoterm)"
                @click="() => state.delivery.useAccountAddress = !state.delivery.useAccountAddress"
              />
              <UButtonGroup class="flex w-full pl-1">
                <SelectMenuBase
                  v-model="state.branchId"
                  :options="utils.maps.branchesByAccountId?.value.get(state.accountId)?.map(toOption) ?? []"
                  class="flex-1"
                  :disabled="['EXW', 'pending'].includes(state.delivery.incoterm) || disabledFields?.includes('branchId') || !state.accountId"
                  @update:model-value="onUpdateBranchId"
                />
                <UButton
                  icon="i-mdi-plus"
                  :disabled="['EXW', 'pending'].includes(state.delivery.incoterm) || disabledFields?.includes('branchId') || !state.accountId"
                  @click="openNested(() => branches$.slideoverOpenCreate({
                    data: { accountId: state.accountId },
                    onSubmit: async (event) => {
                      const branch = await branches$.onSubmitCreate(event.data)
                      state.branchId = branch!.id
                      await openNested(() => slideoverOpenEdit({ ...props, data: model }))
                    },
                  }))"
                />
              </UButtonGroup>
            </UButtonGroup>
          </UFormGroup>

          <FieldsetAddress
            v-if="!['pending', 'EXW'].includes(state.delivery.incoterm)"
            v-model="state.delivery.address"
            disabled
          />

          <Alert
            type="info"
            description="Para actualizar la dirección es necesario editar la sucursal o el domicilio fiscal."
          />

          <UFormGroup
            label="Detalles"
            name="delivery.text"
            :required="isRequired(schema, 'delivery.text')"
          >
            <UTextarea
              v-model="state.delivery.text"
              autoresize
              :disabled="disabledFields?.includes('delivery.text')"
            />
          </UFormGroup>
        </template>

        <template #payment>
          <InputPayment
            v-model="state.payment"
            :schema="schema"
            :disabled-fields="disabledFields"
          />
        </template>

        <template #notes>
          <InputStrings v-model="state.notes" />
        </template>

        <template #pdfSettings>
          <FieldsetPdfSettings
            v-model="state!.pdfSettings"
            :default-password="state.receiver?.taxId ?? ''"
            :cols="2"
          />
        </template>

        <template #data>
          <AppSalesordersFieldsetCustomData v-model="state" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          label="Cancelar"
          variant="outline"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.salesorders"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
