<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types';
import { parseISO } from 'date-fns';

const props = defineProps<{
  data: Partial<Productionorder>
  onSubmit: (event: FormSubmitEvent<Partial<Productionorder>>) => void
  title?: string
  disabledFields?: (keyof Productionorder | string)[]
  readonly?: boolean
}>()

const router = useRouter()
const { user } = useUserSession()
const { moduleId, userModule } = useModules()

const inert = computed(() => props.readonly || props.data?.$inmutable || !['admin', 'edit'].includes(userModule.value?.role))

const state = ref<Partial<Productionorder>>(props.data)

const {
  onSubmitCreate,
  onSubmitUpdate,
  onSubmitDelete,
  onSubmitUpdateMultiple,
  slideover,
  slideoverOpenCreate,
  slideoverOpenEdit,
  slideoverOpenInformation,
  slideoverOpenFilePreview,
  modal,
  modalOpenDelete,
  schema,
  createUidProductionorders,
  getDropdownItems,
  createPdf,
  createFileProductionorderPdf,
} = useProductionorders()

state.value.uid ||= createUidProductionorders(state.value)

const $salesorders = await useFetch<Salesorder[]>('/api/db/salesorders', {
  query: { $with: relationsSalesorders },
  default: () => [],
})
const $users = await useFetch<User[]>('/api/db/users', {
  query: { $columns: ['id', 'name', 'image'] },
  default: () => [],
})

const utils = useProductionordersUtils({ $salesorders, $users })

const items = computed<AccordionItem[]>(() => {
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: ICONS.productionorders,
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Información adicional',
      icon: 'i-mdi-dots-horizontal',
      defaultOpen: true,
    },
    {
      slot: 'pdfSettings',
      label: 'Configuración del PDF',
      icon: 'i-mdi-file-pdf',
      defaultOpen: false,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const informationSalesorder = computed(() => {
  const salesorder = utils.maps.salesorderId.value.get(state.value.salesorderId)
  if (!salesorder) return []
  const user = salesorder?.user?.name
  const account = salesorder?.account?.name
  const localDate = parseISO(salesorder.delivery?.date)
  const delivery = !salesorder.delivery?.isDatetime ? localDate.toLocaleDateString() : localDate.toLocaleString()
  return [
    ['Vendedor', user],
    ['Cliente', account],
    ['Fecha de entrega', delivery],
  ]
})

const loading = ref(false)

const onSubmitProductionorder = async (event: FormSubmitEvent<Partial<Productionorder>>) => {
  loading.value = true
  event.data.uid ||= createUidProductionorders(event.data)
  if (event.data.type !== 'external') {
    event.data.salesorderId = null
  }
  await props.onSubmit(event)
  router.push(`/${moduleId.value}/productionorders/${event.data.id}/items`)
}
</script>

<template>
  <UDashboardSlideover prevent-close>
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.productionorders"
      :schema="schema"
      :validate-on="['submit']"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitProductionorder"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="utils.options.type"
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <UFormGroup
            v-if="state.type === 'external'"
            label="Orden de venta"
            name="salesorderId"
            required
          >
            <UButtonGroup class="flex">
              <SelectMenuBase
                v-model="state.salesorderId"
                :options="utils.options.salesorderId.value"
                :disabled="disabledFields?.includes('salesorderId')"
                class="flex-1"
              />
              <UButton icon="i-mdi-plus" disabled />
            </UButtonGroup>
          </UFormGroup>

          <ViewRendererInformation
            v-if="state.type === 'external' && state.salesorderId"
            title="Información asociada a la orden de venta"
            :default-open="true"
            :entries="informationSalesorder"
          />

          <FieldsetDateRange
            v-model:is-datetime="state.isDatetime"
            v-model:date-start="state.dateStart"
            v-model:date-end="state.dateEnd"
            :is-required-start-date="isRequired(schema, 'dateStart')"
            :is-required-end-date="isRequired(schema, 'dateEnd')"
            :disabled-fields="disabledFields"
          />
        </template>

        <template #details>
          <UFormGroup
            label="Etapa"
            name="stage"
            :required="isRequired(schema, 'stage')"
          >
            <SelectMenuBaseInfo
              v-model="state.stage"
              :options="utils.options.stage"
              :disabled="disabledFields?.includes('stage')"
            />
          </UFormGroup>

          <UFormGroup
            label="Estado"
            name="status"
            :required="isRequired(schema, 'status')"
          >
            <SelectMenuBaseInfo
              v-model="state.status"
              :options="utils.options.status"
              :disabled="disabledFields?.includes('status')"
            />
          </UFormGroup>

          <UFormGroup
            label="Prioridad"
            name="priority"
            :required="isRequired(schema, 'priority')"
          >
            <SelectMenuBaseInfo
              v-model="state.priority"
              :options="utils.options.priority"
              :disabled="disabledFields?.includes('priority')"
            />
          </UFormGroup>

          <UFormGroup
            label="Responsable"
            name="userId"
            required
          >
            <SelectMenuBase
              v-model="state.userId"
              :options="utils.options.userId.value"
              :disabled="disabledFields?.includes('userId')"
            />
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              :creatable="true"
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              accept="*"
              :query="{ prefix: `productionorders/${state.id}/files` }"
            />
          </UFormGroup>

          <UFormGroup
            label="Detalles"
            name="text"
            :required="isRequired(schema, 'text')"
          >
            <UTextarea
              v-model="state.text"
              autoresize
              :disabled="disabledFields?.includes('text')"
            />
          </UFormGroup>
        </template>

        <template #pdfSettings>
          <FieldsetPdfSettings
            v-model="state!.pdfSettings"
            :cols="2"
          />
        </template>

        <template #data>
          <AppProductionordersFieldsetCustomData v-model="state" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          label="Cancelar"
          variant="outline"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.productionorders"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
