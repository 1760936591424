import type { QuotesUtils } from '#imports'
import { createId, type Options } from '#netzo/utils/app/index'
import { toCurrency } from '#netzo/utils/i18n'
import { addDays, format } from 'date-fns'
import { merge } from 'lodash-es'
import type { Content, ContentColumns, TDocumentDefinitions, TDocumentInformation } from 'pdfmake/interfaces'

// copied from server/database/schema.ts

export const getDefaultQuote = (data: Partial<Quote>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)
  type: 'totalized',
  stage: 'draft',
  status: 'pending',
  receiver: {
    taxCountryCode: 'MX',
    address: {},
  },
  issuer: {
    taxId: defaultLegalInformation?.taxId,
    taxCountryCode: defaultLegalInformation?.taxCountryCode,
    legalName: defaultLegalInformation?.legalName,
    satFiscalRegime: defaultLegalInformation?.satFiscalRegime,
    phone: defaultLegalInformation?.phone,
    email: defaultLegalInformation?.email,
    issuedIn: defaultLegalInformation?.address?.postalCode + ' ' + defaultLegalInformation?.address?.state,
    cfdiType: 'I',
    address: defaultLegalInformation?.address ?? {},
  },
  message: {
    enabled: false,
    subject: 'Estimado cliente,',
    body: 'En seguimiento a su solicitud de nuestros productos y servicios, presentamos nuestra cotización con las características que se encontrarán a continuación. Agradecemos su tiempo e interés. Por favor, cualquier duda y/o aclaración, háganoslo saber.',
  },
  isDatetime: false,
  issueDate: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
  expirationDate: new Date(`${format(addDays(new Date(), 14), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
  delivery: {
    incoterm: 'DAP',
    isDatetime: false,
    date: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
    useAccountAddress: true,
    address: {},
  },
  payment: merge(defaultPayment, data?.payment),
  paymentAccounts: [],
  notes: data?.notes ?? [
    'La información contenida en esta cotización es confidencial y está destinada exclusivamente para el uso del destinatario. La divulgación, distribución o copia no autorizada está prohibida.',
  ],
  fileQuotePdf: [],
  tags: [],
  files: [],
  pdfSettings: merge(defaultPdfSettings, { watermark: { text: 'LATINTA' } }),
}, data)

export const optionsQuotes = {
  type: [
    { value: 'totalized', label: 'Totalizada', description: 'La cotización muestra la suma total de todos los conceptos.', color: 'blue' },
    { value: 'itemized', label: 'Sin totalizar', description: 'La cotización solamente muestra los precios de cada concepto.', color: 'green' },
  ],
  stage: [
    { value: 'draft', label: 'Borrador', description: 'La cotización está en proceso de creación y no esta finalizada.', icon: 'i-mdi-circle-outline', color: 'gray' },
    { value: 'approval', label: 'Aprobación', description: 'Cotización en etapa de aprobación interna antes de ser enviada al cliente.', icon: 'i-mdi-check-circle', color: 'amber' },
    { value: 'send', label: 'Envio', description: 'Cotización enviada al cliente para su revisión.', icon: 'i-mdi-send', color: 'blue' },
    { value: 'accepted', label: 'Aceptada', description: 'Cotización aceptada por el cliente.', icon: 'i-mdi-thumb-up', color: 'green' },
    { value: 'rejected', label: 'Rechazada', description: 'Cotización rechazada por el cliente.', icon: 'i-mdi-thumb-down', color: 'red' },
    { value: 'canceled', label: 'Cancelada', description: 'Cotización cancelada por cualquier motivo que no sea aceptación o rechazo.', icon: 'i-mdi-close-circle-outline', color: 'red' },
  ],
  status: optionsSharedApp.statusProcess,
  delivery: {
    useAccountAddress: [
      { value: 'true', label: 'Enviar a domicilio fiscal', icon: 'i-mdi-home', color: 'blue' },
      { value: 'false', label: 'Enviar a sucursal', icon: 'i-mdi-store', color: 'green' },
    ],
    incoterm: optionsShared.incoterm,
  },
} satisfies Options

export const relationsQuotes: Record<string, boolean> = {
  account: true,
  branch: true,
  contact: true,
  deal: true,
  quoteitems: true,
  salesorder: true,
  user: true,
}

export const createPdfmakeDocumentDefinitionQuotes = async (
  data: Quote,
  utils: QuotesUtils,
  user: User,
): Promise<TDocumentDefinitions> => {
  // see https://pdfmake.github.io/docs/0.1/document-definition-object/images/
  const { baseUrl } = useRuntimeConfig().public // new URL('/api/blob/...', baseUrl).href

  data.delivery ??= {}
  data.payment ??= {}
  data.paymentAccounts ??= []
  data.data ??= {}
  data.quoteitems ??= [] // related field

  const exchangeRateValue = data.payment.exchangeRate?.value ?? 1
  const exchangeRateDate = (data.payment.exchangeRate?.date ?? new Date().toISOString())?.split('T')[0]

  const amount = data.quoteitems.reduce((acc: number, quoteitem: Quoteitem) => acc + quoteitem.amount, 0) * exchangeRateValue
  const vat = data.quoteitems.reduce((acc: number, quoteitem: Quoteitem) => {
    const { transfers = [] } = quoteitem.taxes ?? {}
    return acc + transfers.reduce((acc, tax) => acc + tax.amount, 0)
  }, 0) * exchangeRateValue
  const total = amount + vat

  // sections:

  const info: TDocumentInformation = {
    title: `${data.uid}`,
    subject: 'Cotización La Tinta',
    keywords: 'diseño, agencia, publicidad',
    author: 'LA TINTA',
    producer: 'Netzo (https://netzo.dev)',
  }

  const sectionHeader: ContentColumns = {
    columns: [
      { image: SYMBOL_BASE64, height: 90, width: 90 },
      {
        stack: [
          { text: 'Cotización', fontSize: 18, alignment: 'right', margin: [0, 0, 0, 5] },
          { text: [{ text: 'Referencia: ', bold: true }, `${data.uid.toUpperCase()}`], alignment: 'right' },
          { text: [{ text: 'Fecha de emisión: ', bold: true }, new Date(data.issueDate).toLocaleDateString()], alignment: 'right' },
          { text: [{ text: 'Fecha de vencimiento: ', bold: true }, new Date(data.expirationDate).toLocaleDateString()], alignment: 'right' },
          { text: [{ text: 'Vendedor: ', bold: true }, user?.name], alignment: 'right' },
          { text: [{ text: 'Lugar de expedición: ', bold: true }, `${data.issuer.address.postalCode} ${data.issuer.address.state?.toUpperCase()}`], alignment: 'right' },
        ],
        margin: [0, 10],
      },
    ],
    margin: [0, 0, 0, 5],
  }

  const sectionMessage: Content[] = data.message.enabled
    ? [
        data.message.subject && { text: data.message.subject, bold: true, fontSize: 10, margin: [0, 0, 0, 5] },
        data.message.body && { text: data.message.body, fontSize: 9, margin: [0, 0, 0, 5], color: '#555555', lineHeight: 1.2 },
      ].filter(Boolean)
    : []

  const sectionClientInfo: ContentColumns = {
    columns: [
      {
        stack: [
          { text: 'Emisor', style: 'header', margin: [0, 0, 0, 5] },
          { text: `${data.issuer.legalName}`, margin: [0, 0, 0, 2] },
          { text: `${data.issuer.taxId}`, margin: [0, 0, 0, 2] },
          { text: formatAddress(data.issuer.address), margin: [0, 0, 0, 2] },
          { text: [{ text: 'Regimen fiscal: ', bold: true }, utils.maps.billing?.satFiscalRegime.get(data.issuer.satFiscalRegime)?.label] },
        ],
        width: '33%',
        margin: [0, 10],
      },
      {
        stack: [
          { text: 'Receptor', style: 'header', margin: [0, 0, 0, 5] },
          { text: data.receiver.legalName ?? 'Por definir', margin: [0, 0, 0, 2] },
          { text: data.receiver.taxId ?? 'Por definir', margin: [0, 0, 0, 2] },
          { text: formatAddress(data.receiver.address), margin: [0, 0, 0, 2] },
        ],
        width: '33%',
        margin: [10, 10],
      },
      {
        stack: [
          { text: 'Entrega', style: 'header', margin: [0, 0, 0, 5] },
          { text: [{ text: 'Fecha: ', bold: true }, format(new Date(data.delivery.date), 'yyyy-MM-dd HH:mm')], margin: [0, 0, 0, 2] },
          { text: [{ text: 'Términos: ', bold: true }, utils.maps.delivery.incoterm.get(data.delivery.incoterm)?.label], margin: [0, 0, 0, 2] },
          { text: [{ text: 'Dirección: ', bold: true }, formatAddress(data.delivery.address)], margin: [0, 0, 0, 2] },
          ...(data.delivery.text ? [{ text: [{ text: 'Detalles: ', bold: true }, data.delivery.text] }] : []),
        ],
        width: '33%',
        margin: [10, 10],
      },
      data.payment.currency !== defaultPayment.currency
        ? {
            stack: [
              { text: 'Moneda', style: 'header', margin: [0, 5, 0, 5] },
              { text: [{ text: 'Precios base: ', bold: true }, utils.maps.payment.currency.get(defaultPayment.currency)?.label] },
              { text: [{ text: 'Moneda: ', bold: true }, utils.maps.payment.currency.get(data.payment.currency)?.label] },
              { text: [{ text: 'Tipo de cambio (referencia): ', bold: true }, toCurrency(exchangeRateValue, data.payment.currency)] },
              { text: [{ text: 'Fecha referencia: ', bold: true }, exchangeRateDate] },
            ],
            width: '30%',
            margin: [0, 10],
          }
        : {},
    ],
  }

  const sectionQuoteitems: Content = {
    table: {
      headerRows: 1,
      widths: data.pdfSettings.images ? ['auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto'] : ['auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto'],
      body: [
        [
          { text: '#', bold: true, fillColor: '#aaa', fillOpacity: 0.25 },
          { text: 'SKU', bold: true, fillColor: '#aaa', fillOpacity: 0.25 },
          { text: 'Cantidad', bold: true, fillColor: '#aaa', fillOpacity: 0.25, alignment: 'center' },
          ...data.pdfSettings.images ? [{ text: 'Imagen', bold: true, fillColor: '#aaa', fillOpacity: 0.25 }] : [],
          { text: 'Item(s)', bold: true, fillColor: '#aaa', fillOpacity: 0.25 },
          { text: 'Unidad', bold: true, fillColor: '#aaa', fillOpacity: 0.25, alignment: 'center' },
          { text: 'Precio U', bold: true, alignment: 'right', fillColor: '#aaa', fillOpacity: 0.25 },
          { text: 'Descuento', bold: true, alignment: 'right', fillColor: '#aaa', fillOpacity: 0.25 },
          { text: 'Subtotal', bold: true, alignment: 'right', fillColor: '#aaa', fillOpacity: 0.25 },
        ],
        ...(await Promise.all(data.quoteitems.map(async (quoteitem: Quoteitem, index: number) => [
          { text: index + 1, margin: [0, 5, 0, 0] },
          { text: quoteitem.sku, margin: [0, 5, 0, 0] },
          { text: quoteitem.quantity?.toString() ?? '', alignment: 'center', margin: [0, 5, 0, 0] },
          ...(data.pdfSettings.images ? [quoteitem.image ? { image: await getBase64ImageFromURL(quoteitem.image), width: 40, height: 40, margin: [5, 5, 5, 5] } : { text: '' }] : []),
          {
            stack: [
              { text: quoteitem.name, fontSize: 8, margin: [0, 5, 0, 0] },
              quoteitem.description && { text: quoteitem.description, fontSize: 6, margin: [0, 5, 0, 0] },
              { text: utils.maps.billing?.satTaxObject.get(quoteitem.fiscalData.satTaxObject)?.label, fontSize: 6, bold: true, margin: [0, 5, 0, 0] },
            ].filter(Boolean),
          },
          { text: quoteitem.fiscalData.satUnitKey || '', alignment: 'center', margin: [0, 5, 0, 0] },
          { text: toCurrency(Number(quoteitem.unitPrice) * Number(exchangeRateValue), data.payment.currency), alignment: 'right', margin: [0, 5, 0, 0] },
          { text: toCurrency(Number(quoteitem.discount.amount) * Number(exchangeRateValue), data.payment.currency), alignment: 'right', margin: [0, 5, 0, 0] },
          { text: toCurrency(Number(quoteitem.amount) * Number(exchangeRateValue), data.payment.currency), alignment: 'right', margin: [0, 5, 0, 0] },
        ]))),
      ].filter(Boolean),
    },
    layout: 'noBorders',
  }

  const toWords = toSpelledCurrency(data.payment.currency)

  const sectionTotalizedContent: Content[] = data.type === 'totalized'
    ? [
        {
          columns: [
            {
              widths: 'auto',
              margin: [0, 5],
              align: 'right',
              table: {
                widths: ['*', 'auto', 'auto'],
                body: [
                  [{ text: '' }, { text: 'Subtotal:', alignment: 'left' }, { text: `${toCurrency(amount, data.payment.currency)}`, alignment: 'right' }],
                  [{ text: '' }, { text: 'IVA:', alignment: 'left' }, { text: `${toCurrency(vat, data.payment.currency)}`, alignment: 'right' }],
                  [{ text: `${toWords.convert(total)?.toUpperCase()}`, alignment: 'right' }, { text: 'Total:', bold: true, alignment: 'left' }, { text: `${toCurrency(total, data.payment.currency)}`, bold: true, alignment: 'right' }],
                ],
              },
              layout: 'noBorders',
            },
          ],
        },
      ]
    : []

  const sectionNotes: Content[] = data.notes.length
    ? [
        {
          text: 'Observaciones',
          style: 'header',
          margin: [0, 10, 0, 5],
        },
        {
          ul: data.notes.map(note => ({
            text: note,
            color: '#595959',
            margin: [0, 0, 0, 2],
            alignment: 'justify',
          })),
        },
      ]
    : []

  const sectionPaymentAccounts: Content[] = data.paymentAccounts.length
    ? [
        {
          text: 'Cuentas bancarias',
          style: 'header',
          margin: [0, 10, 0, 5],
        },
        {
          text: 'Su pedido sera programado mediante su deposito o transferencia bancaria a las siguientes cuentas:',
          fontSize: 9,
          margin: [0, 0, 0, 2],
          color: '#555555',
          lineHeight: 1.2,
        },
        {
          table: {
            headerRows: 1,
            widths: ['auto', '*', '*', '*', '*'],
            body: [
              [{
                text: '#',
                bold: true,
                fillColor: '#aaa',
                fillOpacity: 0.25,
                // color: 'white',
                margin: [5, 2, 0, 2],
              },
              {
                text: 'Banco',
                bold: true,
                fillColor: '#aaa',
                fillOpacity: 0.25,
                // color: 'white',
                margin: [0, 2, 0, 2],
              },
              {
                text: 'Moneda',
                bold: true,
                fillColor: '#aaa',
                fillOpacity: 0.25,
                // color: 'white',
                margin: [0, 2, 0, 2],
              },
              {
                text: 'No. Cuenta',
                bold: true,
                fillColor: '#aaa',
                fillOpacity: 0.25,
                // color: 'white',
                margin: [0, 2, 0, 2],
              },
              {
                text: 'CLABE',
                bold: true,
                fillColor: '#aaa',
                fillOpacity: 0.25,
                // color: 'white',
                margin: [0, 2, 0, 2],
              },
              ],
              ...data.paymentAccounts.map((account, index) => [
                {
                  text: index + 1,
                  bold: false,
                  margin: [5, 2, 0, 2],
                },
                {
                  text: account.name,
                  bold: false,
                  margin: [0, 2, 0, 2],
                },
                {
                  text: utils.maps.payment.currency.get(account.currency)?.label,
                  bold: false,
                  margin: [0, 2, 0, 2],
                },
                {
                  text: account.accountNumber,
                  bold: false,
                  margin: [0, 2, 0, 2],
                },
                {
                  text: account.clabe,
                  bold: false,
                  margin: [0, 2, 0, 2],
                },
              ]),
            ],
          },
          layout: {
            hLineWidth: function (i, node) {
              return 1
            },
            vLineWidth: function (i) {
              return 0
            },
            hLineColor: function (i) {
              return '#aaa'
            },
            paddingLeft: function (i) {
              return i === 0 ? 0 : 8
            },
            paddingRight: function (i) {
              return i === 0 ? 0 : 8
            },
            paddingTop: function (i, node) {
              return node.table.headerRows === i ? 0 : 2
            },
            paddingBottom: function (i, node) {
              return node.table.headerRows === i ? 0 : 2
            },
          // fillColor: function (rowIndex) {
          //   return rowIndex % 2 !== 0 ? '#FFFFFF' : '#FAF9F6'
          // },
          },
          margin: [0, 10],
        },
      ]
    : []

  return {
    language: 'es-ES',
    userPassword: data.pdfSettings.password?.enabled ? data.pdfSettings.password?.value : undefined,
    watermark: data.pdfSettings.watermark?.enabled
      ? {
          text: data.pdfSettings.watermark.text,
          color: data.pdfSettings.watermark.color,
          bold: data.pdfSettings.watermark.bold,
          italics: data.pdfSettings.watermark.italic,
          opacity: 0.05,
        }
      : undefined,
    info,
    content: [
      sectionHeader,
      ...sectionMessage,
      sectionClientInfo,
      sectionQuoteitems,
      ...sectionTotalizedContent,
      ...sectionNotes,
      ...sectionPaymentAccounts,
    ],
    footer: (currentPage, pageCount, pageSize) => {
      return {
        columns: [
          { image: LOGO_BASE64, fit: [80, 20], margin: [20, 10, 0, 0] },
          {
            stack: [
              { text: 'Representación impresa de un CFDI' },
              {
                text: [
                  { text: 'Generado con ' },
                  { text: 'netzo.dev', link: 'https://netzo.dev', color: 'blue' },
                ],
              },
            ],
            alignment: 'center',
            margin: [0, 10, 0, 0],
          },
          { text: `Página ${currentPage.toString()} de ${pageCount}`, alignment: 'right', margin: [0, 10, 20, 0] },
        ],
        widths: ['auto', '*', 'auto'],
      }
    },
    styles: {
      header: {
        fontSize: 10,
        bold: true,
        margin: [0, 0, 10, 5],
      },
    },
    defaultStyle: {
      fontSize: 8,
    },
    pageSize: 'LETTER',
    pageMargins: [20, 20, 20, 40],
  }
}
