<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types';

const props = defineProps<{
  data: Partial<Contact>
  onSubmit: (event: FormSubmitEvent<Partial<Contact>>) => void
  title?: string
  disabledFields?: (keyof Contact | string)[]
  readonly?: boolean
}>()

const { user } = useUserSession()
const { userModule } = useModules()

const inert = computed(() => props.readonly || props.data?.$inmutable || !['admin', 'edit'].includes(userModule.value?.role))

const state = ref<Partial<Contact>>(props.data)

const {
  onSubmitCreate,
  onSubmitUpdate,
  onSubmitDelete,
  onSubmitUpdateMultiple,
  slideover,
  slideoverOpenCreate,
  slideoverOpenEdit,
  slideoverOpenInformation,
  slideoverOpenFilePreview,
  modal,
  modalOpenDelete,
  schema,
  getDropdownItems,
} = useContacts()

const $accounts = await useFetch<Account[]>('/api/db/accounts', {
  query: { $columns: ['id', 'name', 'image', 'userId'] },
  default: () => [],
})
const $branches = await useFetch<Branch[]>('/api/db/branches', {
  query: { $columns: ['id', 'name', 'image', 'status', 'accountId', 'address'] },
  default: () => [],
})
const $users = await useFetch<Account[]>('/api/db/users', {
  query: { $columns: ['id', 'name', 'image'] },
  default: () => [],
})

const utils = useContactsUtils({ $accounts, $branches, $users })

const accounts$ = useAccounts()
const branches$ = useBranches()

const items = computed<AccordionItem[]>(() => {
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: ICONS.contacts,
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Información adicional',
      icon: 'i-mdi-dots-horizontal',
      defaultOpen: true,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const onUpdateAccountId = async (accountId: string) => {
  const account = utils.maps.accountId.value.get(accountId) as Account
  state.value.userId = account.userId
}

const branchInformation = computed(() => {
  return [
    ['Dirección', formatAddress(utils.maps.branchId.value.get(state.value.branchId)?.address) ?? ''],
  ]
})

const loading = ref(false)

const onSubmitContact = async (event: FormSubmitEvent<Partial<Contact>>) => {
  loading.value = true
  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardSlideover prevent-close>
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.contacts"
      :schema="schema"
      :validate-on="['submit']"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitContact"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <UFormGroup
            label="Titulo"
            name="title"
            :required="isRequired(schema, 'title')"
          >
            <UInput
              v-model="state.title"
              autofocus
              :disabled="disabledFields?.includes('title')"
            />
          </UFormGroup>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(schema, 'name')"
          >
            <UInput
              v-model="state.name"
              :disabled="disabledFields?.includes('name')"
            />
          </UFormGroup>

          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="optionsContacts.type"
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <UFormGroup
            label="Estado"
            name="status"
            :required="isRequired(schema, 'status')"
          >
            <SelectMenuBaseInfo
              v-model="state.status"
              :options="optionsContacts.status"
              :disabled="disabledFields?.includes('status')"
            />
          </UFormGroup>
        </template>

        <template #details>
          <UFormGroup
            label="Cuenta"
            name="accountId"
            :required="isRequired(schema, 'accountId')"
          >
            <div class="flex gap-1">
              <UButtonGroup class="flex-1">
                <SelectMenuNative
                  v-model="state.accountId"
                  :options="utils.options.accountId.value"
                  :disabled="disabledFields?.includes('accountId')"
                  class="flex-1"
                  @update:model-value="onUpdateAccountId"
                />
                <UButton
                  icon="i-mdi-plus"
                  :disabled="disabledFields?.includes('accountId')"
                  @click="openNested(() => accounts$.slideoverOpenCreate({
                    data: {
                      type: 'customer',
                      status: 'active',
                    },
                    onSubmit: async (event) => {
                      const account = await accounts$.onSubmitCreate(event.data)
                      state.accountId = account!.id
                      state.userId = account!.userId
                      await $accounts.refresh()
                      await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                    },
                  }))"
                />
              </UButtonGroup>
              <ButtonRefresh
                :disabled="!state.accountId"
                @click="onUpdateAccountId(state.accountId)"
              />
            </div>
          </UFormGroup>

          <UFormGroup
            v-if="state.type === 'customer'"
            label="Sucursal"
            name="branchId"
            :required="isRequired(schema, 'branchId')"
          >
            <UButtonGroup class="flex">
              <SelectMenuBase
                v-model="state.branchId"
                :options="utils.maps.branchesByAccountId.value.get(state.accountId)?.map(toOption) ?? []"
                :disabled="disabledFields?.includes('branchId') || !state.accountId"
                class="flex-1"
              />
              <UButton
                icon="i-mdi-plus"
                :disabled="disabledFields?.includes('branchId') || !state.accountId"
                @click="openNested(() => branches$.slideoverOpenCreate({
                  data: { accountId: state.accountId },
                  onSubmit: async (event) => {
                    const branch = await branches$.onSubmitCreate(event.data)
                    state.branchId = branch!.id
                    await $branches.refresh()
                    await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                  },
                }))"
              />
            </UButtonGroup>
          </UFormGroup>

          <ViewRendererInformation
            v-if="state.branchId"
            title="Información de la sucursal"
            :entries="branchInformation"
          />

          <UFormGroup
            label="Dueño"
            name="userId"
            :required="isRequired(schema, 'userId')"
          >
            <SelectMenuBase
              v-model="state.userId"
              :options="utils.options.userId.value"
              :disabled="disabledFields?.includes('userId')"
            />
          </UFormGroup>

          <UFormGroup
            label="Email"
            name="email"
            :required="isRequired(schema, 'email')"
          >
            <UInput
              v-model="state.email"
              type="email"
              :disabled="disabledFields?.includes('email')"
            />
          </UFormGroup>

          <UFormGroup
            label="Email marketing"
            name="emailOptIn"
            :required="isRequired(schema, 'emailOptIn')"
          >
            <UToggle
              v-model="state.emailOptIn"
              :disabled="disabledFields?.includes('emailOptIn')"
            />
          </UFormGroup>

          <UFormGroup
            label="Teléfono"
            name="phone"
            :required="isRequired(schema, 'phone')"
          >
            <InputPhone
              v-model="state.phone"
              :disabled="disabledFields?.includes('phone')"
            />
          </UFormGroup>

          <UFormGroup
            name="image"
            label="Imagen"
            :required="isRequired(schema, 'image')"
          >
            <InputImage
              v-model="state.image"
              :query="{ prefix: `contacts/${state.id}` }"
            />
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              :creatable="true"
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              accept="*"
              :query="{ prefix: `contacts/${state.id}/files` }"
            />
          </UFormGroup>
        </template>

        <template #data>
          <AppContactsFieldsetCustomData v-model="state" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          label="Cancelar"
          variant="outline"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.contacts"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
