import { createId, type Options } from '#netzo/utils/app/index'
import { toCurrency } from '#netzo/utils/i18n'
import { format } from 'date-fns'
import { merge } from 'lodash-es'
import type { Content, ContentColumns, TDocumentDefinitions, TDocumentInformation } from 'pdfmake/interfaces'

export const getDefaultSalesorder = (data: Partial<Salesorder>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)
  type: 'quote',
  stage: 'received',
  status: 'pending',
  date: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
  receiver: {
    taxCountryCode: 'MX',
    address: {},
  },
  issuer: {
    taxId: defaultLegalInformation?.taxId,
    taxCountryCode: defaultLegalInformation?.taxCountryCode,
    legalName: defaultLegalInformation?.legalName,
    satFiscalRegime: defaultLegalInformation?.satFiscalRegime,
    phone: defaultLegalInformation?.phone,
    email: defaultLegalInformation?.email,
    issuedIn: defaultLegalInformation?.address?.postalCode + ' ' + defaultLegalInformation?.address?.state,
    cfdiType: 'I',
    address: defaultLegalInformation?.address || {},
  },
  delivery: {
    incoterm: 'DAP',
    isDatetime: false,
    date: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
    address: {},
    text: '',
  },
  payment: merge(defaultPayment, data?.payment),
  notes: data?.notes ?? [],
  fileSalesorderPdf: [],
  tags: [],
  files: [],
  pdfSettings: merge(defaultPdfSettings, { watermark: { text: 'LATINTA' } }),
}, data)

export const optionsSalesorders = {
  type: [
    { value: 'quote', label: 'Cotización', description: 'Orden de venta proviene de una cotización firmada por el cliente que indica su acuerdo con los términos propuestos.', icon: 'i-mdi-file-document-outline', color: 'blue' },
    { value: 'purchaseorder', label: 'Orden de compra', description: 'Orden de venta proviene de una orden de compra oficial emitida por el cliente solicitando productos o servicios.', icon: 'i-mdi-cart', color: 'green' },
    { value: 'payslip', label: 'Recibo de pago', description: 'Orden de ventaproviene de un recibo que indica un pago adelantado realizado por el cliente.', icon: 'i-mdi-cash' },
  ],
  stage: [
    { value: 'received', label: 'Recepción', description: 'El cliente nos envió una orden de venta antes de ser procesada se debe revisar y aprobar.', icon: 'i-mdi-send', color: 'gray' },
    { value: 'approval', label: 'Aprobación', description: 'La orden de ventanecesita ser aprobada antes de ser procesada.', icon: 'i-mdi-check-circle-outline', color: 'amber' },
    { value: 'processing', label: 'Procesamiento', description: 'La orden de venta ha sido aprobada y se deben generar las órdenes de trabajo internas para su ejecución.', icon: 'i-mdi-cog', color: 'blue' },
    { value: 'billing', label: 'Cobranza', description: 'La orden de venta ha sido procesada y se espera el pago del cliente.', icon: 'i-mdi-cash', color: 'green' },
    { value: 'completed', label: 'Completada', description: 'La orden de venta se ha completado con éxito y no requiere más acciones.', icon: 'i-mdi-check-circle-outline', color: 'green' },
    { value: 'canceled', label: 'Cancelada', description: 'La orden de ventase canceló por cualquier motivo.', icon: 'i-mdi-close-circle-outline', color: 'red' },
  ],
  status: optionsSharedApp.statusProcess,
  delivery: {
    useAccountAddress: [
      { value: 'true', label: 'Enviar a domicilio fiscal', icon: 'i-mdi-home', color: 'blue' },
      { value: 'false', label: 'Enviar a sucursal', icon: 'i-mdi-store', color: 'green' },
    ],
    incoterm: optionsShared.incoterm,
  },
} satisfies Options

export const relationsSalesorders: Record<string, boolean> = {
  account: true,
  branch: true,
  contact: true,
  deal: true,
  quote: true,
  salesorderitems: true,
  user: true,
  productionorders: true,
}

export const createPdfmakeDocumentDefinitionSalesorders = async (
  data: Salesorder,
  utils: SalesordersUtils,
  user: User,
): Promise<TDocumentDefinitions> => {
  // see https://pdfmake.github.io/docs/0.1/document-definition-object/images/
  const { baseUrl } = useRuntimeConfig().public // new URL('/api/blob/...', baseUrl).href

  data.delivery ??= {}
  data.payment ??= {}
  data.data ??= {}
  data.salesorderitems ??= [] // related field

  const exchangeRateValue = data.payment.exchangeRate?.value ?? 1
  const exchangeRateDate = (data.payment.exchangeRate?.date ?? new Date().toISOString())?.split('T')[0]

  const amount = data.salesorderitems.reduce((acc: number, salesorderitem: Salesorderitem) => acc + salesorderitem.amount, 0) * exchangeRateValue
  const vat = data.salesorderitems.reduce((acc: number, salesorderitem: Salesorderitem) => {
    const { transfers = [] } = salesorderitem.taxes ?? {}
    return acc + transfers.reduce((acc, tax) => acc + tax.amount, 0)
  }, 0) * exchangeRateValue
  const total = amount + vat

  // sections:

  const info: TDocumentInformation = {
    title: `${data.uid}`,
    subject: 'Orden de Venta La Tinta',
    keywords: 'diseño, agencia, publicidad',
    author: 'LA TINTA',
    producer: 'Netzo (https://netzo.dev)',
  }

  const sectionHeader: ContentColumns = {
    columns: [
      { image: SYMBOL_BASE64, height: 90, width: 90 },
      {
        stack: [
          { text: 'Orden de Venta', fontSize: 18, alignment: 'right', margin: [0, 0, 0, 5] },
          { text: [{ text: 'Referencia: ', bold: true }, `${data.uid.toUpperCase()}`], alignment: 'right' },
          { text: [{ text: 'Fecha: ', bold: true }, new Date(data.date).toLocaleDateString()], alignment: 'right' },
          { text: [{ text: 'Vendedor: ', bold: true }, user?.name], alignment: 'right' },
          { text: [{ text: 'Lugar de expedición: ', bold: true }, `${data.issuer.address.postalCode} ${data.issuer.address.state?.toUpperCase()}`], alignment: 'right' },
        ],
        margin: [0, 10],
      },
    ],
    margin: [0, 0, 0, 5],
  }

  const sectionClientInfo: ContentColumns = {
    columns: [
      {
        stack: [
          { text: 'Emisor', style: 'header', margin: [0, 0, 0, 5] },
          { text: `${data.issuer.legalName}`, margin: [0, 0, 0, 2] },
          { text: `${data.issuer.taxId}`, margin: [0, 0, 0, 2] },
          { text: formatAddress(data.issuer.address), margin: [0, 0, 0, 2] },
          { text: [{ text: 'Regimen fiscal: ', bold: true }, utils.maps.billing?.satFiscalRegime.get(data.issuer.satFiscalRegime)?.label] },
        ],
        width: '33%',
        margin: [0, 10],
      },
      {
        stack: [
          { text: 'Receptor', style: 'header', margin: [0, 0, 0, 5] },
          { text: data.receiver.legalName ?? 'Por definir', margin: [0, 0, 0, 2] },
          { text: data.receiver.taxId ?? 'Por definir', margin: [0, 0, 0, 2] },
          { text: formatAddress(data.receiver.address), margin: [0, 0, 0, 2] },
        ],
        width: '33%',
        margin: [10, 10],
      },
      {
        stack: [
          { text: 'Entrega', style: 'header', margin: [0, 0, 0, 5] },
          { text: [{ text: 'Fecha: ', bold: true }, format(new Date(data.delivery.date), 'yyyy-MM-dd HH:mm')], margin: [0, 0, 0, 2] },
          { text: [{ text: 'Términos: ', bold: true }, utils.maps.delivery.incoterm.get(data.delivery.incoterm)?.label], margin: [0, 0, 0, 2] },
          { text: [{ text: 'Dirección: ', bold: true }, formatAddress(data.delivery.address)], margin: [0, 0, 0, 2] },
          ...(data.delivery.text ? [{ text: [{ text: 'Detalles: ', bold: true }, data.delivery.text] }] : []),
        ],
        width: '33%',
        margin: [10, 10],
      },
      data.payment.currency !== defaultPayment.currency
        ? {
            stack: [
              { text: 'Moneda', style: 'header', margin: [0, 5, 0, 5] },
              { text: [{ text: 'Precios base: ', bold: true }, utils.maps.payment.currency.get(defaultPayment.currency)?.label] },
              { text: [{ text: 'Moneda: ', bold: true }, utils.maps.payment.currency.get(data.payment.currency)?.label] },
              { text: [{ text: 'Tipo de cambio (referencia): ', bold: true }, toCurrency(exchangeRateValue, data.payment.currency)] },
              { text: [{ text: 'Fecha referencia: ', bold: true }, exchangeRateDate] },
            ],
            width: '30%',
            margin: [0, 10],
          }
        : {},
    ],
  }

  const sectionSalesorderitems: Content = {
    table: {
      headerRows: 1,
      widths: data.pdfSettings.images ? ['auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto'] : ['auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto'],
      body: [
        [
          { text: '#', bold: true, fillColor: '#aaa', fillOpacity: 0.25 },
          { text: 'SKU', bold: true, fillColor: '#aaa', fillOpacity: 0.25 },
          { text: 'Cantidad', bold: true, fillColor: '#aaa', fillOpacity: 0.25, alignment: 'center' },
          ...data.pdfSettings.images ? [{ text: 'Imagen', bold: true, fillColor: '#aaa', fillOpacity: 0.25 }] : [],
          { text: 'Item(s)', bold: true, fillColor: '#aaa', fillOpacity: 0.25 },
          { text: 'Unidad', bold: true, fillColor: '#aaa', fillOpacity: 0.25, alignment: 'center' },
          { text: 'Precio U', bold: true, alignment: 'right', fillColor: '#aaa', fillOpacity: 0.25 },
          { text: 'Descuento', bold: true, alignment: 'right', fillColor: '#aaa', fillOpacity: 0.25 },
          { text: 'Subtotal', bold: true, alignment: 'right', fillColor: '#aaa', fillOpacity: 0.25 },
        ],
        ...(await Promise.all(data.salesorderitems.map(async (salesorderitem: Salesorderitem, index: number) => [
          { text: index + 1, margin: [0, 5, 0, 0] },
          { text: salesorderitem.sku, margin: [0, 5, 0, 0] },
          { text: salesorderitem.quantity?.toString() ?? '', alignment: 'center', margin: [0, 5, 0, 0] },
          ...(data.pdfSettings.images ? [salesorderitem.image ? { image: await getBase64ImageFromURL(salesorderitem.image), width: 40, height: 40, margin: [5, 5, 5, 5] } : { text: '' }] : []),
          {
            stack: [
              { text: salesorderitem.name, fontSize: 8, margin: [0, 5, 0, 0] },
              salesorderitem.description && { text: salesorderitem.description, fontSize: 6, margin: [0, 5, 0, 0] },
              { text: utils.maps.billing?.satTaxObject.get(salesorderitem.fiscalData.satTaxObject)?.label, fontSize: 6, bold: true, margin: [0, 5, 0, 0] },
            ].filter(Boolean),
          },
          { text: salesorderitem.fiscalData.satUnitKey || '', alignment: 'center', margin: [0, 5, 0, 0] },
          { text: toCurrency(Number(salesorderitem.unitPrice) * Number(exchangeRateValue), data.payment.currency), alignment: 'right', margin: [0, 5, 0, 0] },
          { text: toCurrency(Number(salesorderitem.discount.amount) * Number(exchangeRateValue), data.payment.currency), alignment: 'right', margin: [0, 5, 0, 0] },
          { text: toCurrency(Number(salesorderitem.amount) * Number(exchangeRateValue), data.payment.currency), alignment: 'right', margin: [0, 5, 0, 0] },
        ]))),
      ].filter(Boolean),
    },
    layout: 'noBorders',
  }

  const toWords = toSpelledCurrency(data.payment.currency)

  const sectionTotalizedContent: Content[] = [
    {
      columns: [
        {
          widths: 'auto',
          margin: [0, 5],
          align: 'right',
          table: {
            widths: ['*', 'auto', 'auto'],
            body: [
              [{ text: '' }, { text: 'Subtotal:', alignment: 'left' }, { text: `${toCurrency(amount, data.payment.currency)}`, alignment: 'right' }],
              [{ text: '' }, { text: 'IVA:', alignment: 'left' }, { text: `${toCurrency(vat, data.payment.currency)}`, alignment: 'right' }],
              [{ text: `${toWords.convert(total)?.toUpperCase()}`, alignment: 'right' }, { text: 'Total:', bold: true, alignment: 'left' }, { text: `${toCurrency(total, data.payment.currency)}`, bold: true, alignment: 'right' }],
            ],
          },
          layout: 'noBorders',
        },
      ],
    },
  ]

  const sectionNotes: Content[] = data.notes.length
    ? [
        {
          text: 'Observaciones',
          style: 'header',
          margin: [0, 10, 0, 5],
        },
        {
          ul: data.notes.map(note => ({
            text: note,
            color: '#595959',
            margin: [0, 0, 0, 2],
            alignment: 'justify',
          })),
        },
      ]
    : []

  return {
    language: 'es-ES',
    userPassword: data.pdfSettings.password?.enabled ? data.pdfSettings.password?.value : undefined,
    watermark: data.pdfSettings.watermark?.enabled
      ? {
          text: data.pdfSettings.watermark.text,
          color: data.pdfSettings.watermark.color,
          bold: data.pdfSettings.watermark.bold,
          italics: data.pdfSettings.watermark.italic,
          opacity: 0.05,
        }
      : undefined,
    info,
    content: [
      sectionHeader,
      sectionClientInfo,
      sectionSalesorderitems,
      ...sectionTotalizedContent,
      ...sectionNotes,
    ],
    footer: (currentPage, pageCount, pageSize) => {
      return {
        columns: [
          { image: LOGO_BASE64, fit: [80, 20], margin: [20, 10, 0, 0] },
          {
            stack: [
              { text: 'Representación impresa de un CFDI' },
              {
                text: [
                  { text: 'Generado con ' },
                  { text: 'netzo.dev', link: 'https://netzo.dev', color: 'blue' },
                ],
              },
            ],
            alignment: 'center',
            margin: [0, 10, 0, 0],
          },
          { text: `Página ${currentPage.toString()} de ${pageCount}`, alignment: 'right', margin: [0, 10, 20, 0] },
        ],
        widths: ['auto', '*', 'auto'],
      }
    },
    styles: {
      header: {
        fontSize: 10,
        bold: true,
        margin: [0, 0, 10, 5],
      },
    },
    defaultStyle: {
      fontSize: 8,
    },
    pageSize: 'LETTER',
    pageMargins: [20, 20, 20, 40],
  }
}
