import type { FormErrorEvent } from '#ui/types'
import { useClipboard } from '@vueuse/core'

export const open = (url: string, target = '_blank') => window?.open(url, target)

/**
 * WORKAROUND: this is a hack to close the modal and slideover before
 * opening another one since nested modals/slideovers are not supported
 * in @nuxt/ui v2 due to a current limitation with HeadlessUI Dialog,
 * TODO: remove this utility once nested modals/slideovers supported in v3
 */
export const openNested = async (callback: () => void) => {
  useModal().close() // either
  useSlideover().close() // or
  await new Promise(res => setTimeout(res, 900)) // wait at least closing transition duration (not more, 900ms works)
  callback()
}

export const copyToClipboard = (text: string) => {
  const { copy } = useClipboard()

  copy(text).then(() => {
    useToastAlert().success(`Copiado al portapapeles`)
  }).catch((err) => {
    useToastAlert().error(`Error al copiar al portapapeles`)
    console.error('Error copying text to clipboard:', err)
  })
}

// view:

// view-table:

export const UI_TABLE = {
  wrapper: 'h-full',
  divide: 'divide-gray-200 dark:divide-gray-800',
  thead: 'sticky top-0 z-10 bg-[rgb(var(--ui-background))]',
  th: { base: 'text-nowrap', padding: 'py-3', color: 'bg-[rgb(var(--ui-background))]', font: 'font-normal' },
  td: { font: 'text-sm', padding: 'py-1 px-2' },
}

// view-cards:

// view-inbox:

// view-kanban:

// view-calendar:

// forms:

export const onFormError = (event: FormErrorEvent) => {
  const element = document.getElementById(event.errors[0]!.id)
  element?.focus()
  element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  useToastAlert().error({
    title: `${event.errors.length} errores de validación`,
    description: 'Revisa los campos marcados en rojo y vuelve a intentarlo.',
  })
  event.errors.forEach((error) => {
    console.warn(`${error.message} '${error.path}'`)
  })
}

export const FIELDSET = 'w-full grid grid-cols-1 gap-2 !mt-4 !mb-2 p-4 border rounded-lg'

export const FIELDSET_FULL = `w-full grid grid-cols-1 md:grid-cols-2 gap-2 !mt-4 !mb-2`

export const FIELDSET_FULL_12 = `w-full grid grid-cols-1 md:grid-cols-12 gap-2 !mt-4 !mb-2`
