import { ModalDelete, SlideoverInformation, SlideoverPreview } from '#components'
import type { ComponentProps } from 'vue-component-type-helpers'

export type Record = {
  id: string
  [k: string]: unknown
}

/**
 * A shared composable with common utilities for records
 */
export const useRecords = <T extends Record>(entity: string) => {
  const slideover = useSlideover()
  const modal = useModal()

  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
  } = useApiDB<T>(entity)

  const slideoverOpenInformation = (entity: string, row: T) => {
    return new Promise<T>((resolve, reject) => {
      try {
        slideover.open(SlideoverInformation, { data: row, entityId: row.id, entity })
        resolve()
      }
      catch (error) {
        slideover.close()
        reject(error)
      }
    })
  }

  const slideoverOpenFilePreview = (id: string, file: { pathname: string }) => {
    return new Promise<T>((resolve, reject) => {
      try {
        slideover.open(SlideoverPreview, {
          src: new URL(`/api/blob/${file.pathname}`, window.location.origin).href,
          title: `${file.pathname.split('/').pop()}`,
          onClickRegenerate: async () => {
            try {
              const result = await onSubmitUpdate({ id, updatedAt: new Date().toISOString() } as Partial<T>, false)
              useToastAlert().success('Archivo regenerado con éxito')
              resolve(result)
            }
            catch (error) {
              useToastAlert().error('Error al regenerar el archivo')
              console.error(error)
            }
          },
        })
      }
      catch (error) {
        slideover.close()
        reject(error)
      }
    })
  }

  const modalOpenDelete = (props: ComponentProps<typeof ModalDelete>) => {
    return new Promise<T[]>((resolve, reject) => {
      try {
        modal.open(ModalDelete, {
          title: 'Eliminar registro',
          onSubmit: props?.onSubmit ?? (async (event) => {
            const result = await onSubmitDelete([event.data])
            modal.close()
            resolve(result)
          }),
        })
      }
      catch (error) {
        slideover.close()
        reject(error)
      }
    })
  }

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  }
}
