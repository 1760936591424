<script setup lang="ts">
const model = defineModel<Fulfillmentorder>({ required: true })

const props = defineProps<{
  utils: FulfillmentordersUtils
  disabled?: boolean
}>()

const $contacts = await useFetch<Contact[]>('/api/db/contacts', {
  query: {},
  default: () => [],
})

const contactOptions = computed(() => $contacts.data.value.map(toOption))
const contactId = computed(() => toMapByKey($contacts.data.value, 'id'))

const contacts$ = useContacts()

const onToggleRequiresInstallation = () => {
  if (!model.value?.data) model.value.data = {}
  model.value.data.requiresRemoval = false
  model.value.data.requiresInstallation = !model.value.data.requiresInstallation
}

const onUpdateContactId = () => {
  const id = model.value.data.contactId
  const contact = contactId.value.get(id)
  model.value.data.whatsapp = contact.phone
}
</script>

<template>
  <fieldset :class="FIELDSET_FULL_12" :disabled="disabled">
    <UFormGroup
      label="Retiro"
      name="data.requiresRemoval"
      class="md:col-span-6"
    >
      <UButtonGroup class="flex">
        <UButton
          :icon="`${model.data.requiresRemoval ? 'i-mdi-checkbox-marked' : 'i-mdi-checkbox-blank-outline'}`"
          square
          variant="outline"
          :ui="{ variant: { outline: 'ring-gray-300 dark:ring-gray-700' } }"
          @click="() => model.data.requiresRemoval = !model.data.requiresRemoval"
        />
        <InputReadOnly
          model-value="Requiere retiro"
          class="flex-1"
        />
      </UButtonGroup>
    </UFormGroup>

    <UFormGroup
      label="Instalación"
      name="data.requiresInstallation"
      class="md:col-span-6"
    >
      <UButtonGroup class="flex">
        <UButton
          :icon="`${model.data.requiresInstallation ? 'i-mdi-checkbox-marked' : 'i-mdi-checkbox-blank-outline'}`"
          square
          variant="outline"
          :ui="{ variant: { outline: 'ring-gray-300 dark:ring-gray-700' } }"
          @click="onToggleRequiresInstallation"
        />
        <InputReadOnly
          model-value="Requiere instalación"
          class="flex-1"
        />
      </UButtonGroup>
    </UFormGroup>

    <UFormGroup
      label="Nombre de contacto"
      name="data.contact"
      class="md:col-span-6"
      :required="model.data.requiresInstallation"
    >
      <UButtonGroup class="flex">
        <SelectMenuBase
          v-model="model.data.contactId"
          :options="contactOptions"
          :required="model.data.requiresInstallation"
          :disabled="!model.data.requiresInstallation"
          class="flex-1"
          @update:model-value="onUpdateContactId"
        />
        <UButton
          icon="i-mdi-plus"
          @click="openNested(() => contacts$.slideoverOpenCreate({
            data: { quoteId: model.id },
            onSubmit: async (event) => {
              const contact = await contacts$.onSubmitCreate(event.data)
              model.contactId = contact!.id
              await $contacts.refresh()
              await openNested(() => slideoverOpenEdit({ ...props, data: model }))
            },
          }))"
        />
      </UButtonGroup>
      <ButtonRefresh
        toast="El whatsapp se ha actualizado con éxito"
        :disabled="!model.data.contactId"
        @click="onUpdateContactId"
      />
    </UFormGroup>

    <UFormGroup
      label="Whatsapp para notificaciones"
      name="data.whatsapp"
      class="md:col-span-6"
      :required="model.data.requiresInstallation"
    >
      <UInput
        v-model="model.data.whatsapp"
        :required="model.data.requiresInstallation"
        :disabled="!model.data.requiresInstallation"
      />
    </UFormGroup>

    <UFormGroup
      label="Alt. máxima (m)"
      name="data.maxHeight"
      class="md:col-span-3"
      :required="model.data.requiresInstallation"
    >
      <UInput
        v-model="model.data.maxHeight"
        type="decimal"
        :min="0.00"
        :max="9999.99"
        :step="0.01"
        :required="model.data.requiresInstallation"
        :disabled="!model.data.requiresInstallation"
      />
    </UFormGroup>

    <UFormGroup
      label="Imagen de referencia"
      name="data.image"
      class="md:col-span-6"
      :required="model.data.requiresInstallation"
    >
      <InputImage
        v-model="model.data.image"
        :query="{ prefix: `datas/${model.id}/installation` }"
        :required="model.data.requiresInstallation"
        :disabled="!model.data.requiresInstallation"
      />
    </UFormGroup>

    <UFormGroup
      label="Detalles"
      name="data.text"
      class="md:col-span-12"
    >
      <UTextarea
        v-model="model.data.text"
        placeholder="Escribe un comentario"
        resize
        class="w-full"
        :disabled="!model.data.requiresInstallation"
      />
    </UFormGroup>
  </fieldset>
</template>
