import { createId, type Options } from '#netzo/utils/app/index'
import type { CalendarEvent } from '@schedule-x/calendar'
import { format } from 'date-fns'
import { merge } from 'lodash-es'

export const getDefaultActivity = (data: Partial<Activity>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)
  type: 'inbound',
  priority: '2',
  status: 'pending',
  isDatetime: false,
  dateStart: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
  dateEnd: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
  contactIds: [],
  userIds: [],
  tags: [],
  files: [],
}, data)

export const optionsActivities = {
  type: [
    { value: 'inbound', label: 'Entrada', description: 'Actividad que representa la recepción de una comunicación o interacción, como una llamada recibida o un correo electrónico entrante.', color: 'blue', icon: 'i-mdi-arrow-down-circle' },
    { value: 'outbound', label: 'Salida', description: 'Actividad que representa la emisión de una comunicación o interacción, como una llamada realizada o un correo electrónico enviado.', color: 'green', icon: 'i-mdi-arrow-up-circle' },
  ],
  subtype: [
    { value: 'email', label: 'Email', description: 'Envío de un correo electrónico.', color: 'purple', icon: 'i-mdi-email' },
    { value: 'call', label: 'Llamada', description: 'Llamada emitida.', color: 'blue', icon: 'i-mdi-phone' },
    { value: 'videocall', label: 'Videollamada', description: 'Reunión virtual mediante video.', color: 'orange', icon: 'i-mdi-video' },
    { value: 'meeting', label: 'Reunión', description: 'Encuentro organizado, presencial o digital.', color: 'purple', icon: 'i-mdi-calendar' },
    { value: 'whatsapp', label: 'WhatsApp', description: 'Envío de mensajes a través de WhatsApp.', color: 'green', icon: 'i-mdi-whatsapp' },
    { value: 'other', label: 'Otro', description: 'Otra forma de comunicación no especificada.', color: 'gray', icon: 'i-mdi-comment' },
  ],
  status: [
    { value: 'pending', label: 'Pendiente', description: 'La actividad está programada pero aún no ha comenzado.', color: 'amber', icon: 'i-mdi-radiobox-blank' },
    { value: 'completed', label: 'Completada', description: 'La actividad se ha completado con éxito.', color: 'green', icon: 'i-mdi-check-circle-outline' },
    { value: 'canceled', label: 'Cancelada', description: 'La actividad fue planificada pero luego cancelada.', color: 'red', icon: 'i-mdi-close-circle' },
    { value: 'rescheduled', label: 'Reprogramada', description: 'La actividad se ha pospuesto y está programada para más tarde.', color: 'blue', icon: 'i-mdi-calendar-clock' },
    { value: 'no-show', label: 'No asistió', description: 'La actividad no se llevó a cabo porque cualquiera de los participantes no se presentó.', color: 'orange', icon: 'i-mdi-account-off' },
    { value: 'other', label: 'Otra', description: 'La actividad tiene un tipo diferente no especificado.', color: 'gray', icon: 'i-mdi-asterisk' },
  ],
  priority: optionsSharedApp.priority,
} satisfies Options

export const relationsActivities: Record<string, boolean> = {
  account: true,
  branch: true,
  deal: true,
}

// views:

export const activityToViewCalendarEvent = (calendarId: keyof Activity, utils: ActivitiesUtils) => {
  return (row: Activity): CalendarEvent => {
    const calendarName = utils.maps[calendarId].get(row[calendarId])?.label?.toUpperCase()
    const dateFormat = row.isDatetime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'
    return {
      ...row,
      id: row.id,
      title: `[${calendarName}] ${row.name}`,
      description: row.text!,
      start: format(new Date(row.dateStart), dateFormat),
      end: format(new Date(row.dateEnd), dateFormat),
      calendarId: row[calendarId],
    }
  }
}
