import { AppFulfillmentordersSlideover, FilesSlideover, SlideoverPreview } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { CalendarEvent } from '@schedule-x/calendar'
import { format } from 'date-fns'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppFulfillmentordersSlideoverProps = ComponentProps<typeof AppFulfillmentordersSlideover>

export type FulfillmentordersUtils = ReturnType<typeof useFulfillmentordersUtils>

// view:

export const VIEW_SETTINGS_FULFILLMENTORDERS: ViewSettings<Fulfillmentorder> = {
  tableName: 'fulfillmentorders',
  views: optionsShared.views.filter(view => ['table', 'kanban', 'calendar'].includes(view.value)),
  selectedView: 'table',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'uid',
      label: 'Referencia',
      sortable: true,
    },
    {
      key: 'fileFulfillmentorderPdf',
      label: 'PDF',
      class: '',
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'stage',
      label: 'Etapa',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'priority',
      label: 'Prioridad',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'dateStart',
      label: 'Fecha de Inicio',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'dateEnd',
      label: 'Fecha de Cierre',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'userId',
      label: 'Responsable',
    },
    {
      key: 'tasks',
      label: 'Tareas',
      class: 'min-w-[200px]',
    },
    {
      key: 'productionorderId',
      label: 'Orden de producción',
    },
    {
      key: 'productionorder.stage',
      label: 'Etapa (OP)',
    },
    {
      key: 'productionorder.status',
      label: 'Estado (OP)',
    },
    {
      key: 'salesorderId',
      label: 'Orden de venta',
    },
    {
      key: 'items',
      label: 'Items',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'contactId',
      label: 'Contacto',
    },
    {
      key: 'delivery.incoterm',
      label: 'Incoterm',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'delivery.useAccountAddress',
      label: 'Opción de entrega',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'branchId',
      label: 'Sucursal',
    },
    {
      key: 'delivery.address',
      label: 'Address',
      class: 'min-w-[200px]', // for <th>
    },
    {
      key: 'text',
      label: 'Detalles',
      class: 'min-w-[300px]',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
  groups: [
    { label: 'Tipo', value: 'type', options: optionsFulfillmentorders.type },
    { label: 'Etapa', value: 'stage', options: optionsFulfillmentorders.stage },
    { label: 'Estado', value: 'status', options: optionsFulfillmentorders.status },
    { label: 'Prioridad', value: 'priority', options: optionsFulfillmentorders.priority },
  ],
  group: 'stage',
}

export const useFulfillmentorders = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Fulfillmentorder>('fulfillmentorders')

  const slideoverOpenCreate = (props: AppFulfillmentordersSlideoverProps) => {
    return new Promise<Fulfillmentorderorder>((resolve, reject) => {
      const data = getDefaultFulfillmentorder(props.data)
      slideover.open(AppFulfillmentordersSlideover, {
        title: 'Registrar orden de cumplimiento',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppFulfillmentordersSlideoverProps) => {
    return new Promise<Fulfillmentorderorder>((resolve, reject) => {
      const data = getDefaultFulfillmentorder(props.data)
      slideover.open(AppFulfillmentordersSlideover, {
        title: 'Editar orden de cumplimiento',
        ...props,
        data,
        disabledFields: ['dealId'],
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    type: z.string(),
    stage: z.string(),
    status: z.string(),
    priority: z.string(),
    uid: z.string(),
    dateStart: z.string(),
    dateEnd: z.string(),
    delivery: z.object({
      incoterm: z.string(),
      useAccountAddress: z.boolean(),
      address: z.object({
        street: z.string(),
        numberExterior: z.string(),
        city: z.string(),
        state: z.string(),
        country: z.string(),
        postalCode: z.string(),
      }),
    }),
    accountId: z.string(),
    salesorderId: z.string(),
    userId: z.string(),
  }).required()

  // actions:

  const createUidFulfillmentorders = (data: Fulfillmentorder) => {
    return createUid(new Date(data.dateStart), 'OC')
  }

  const getDropdownItems = (row: Fulfillmentorder, refresh: () => Promise<void>, utils: FulfillmentordersUtils, dropdownItemsAction: DropdownItem[][] = []): DropdownItem[][] => [
    [
      {
        label: row.$inmutable ? 'Ver detalles' : 'Editar',
        icon: row.$inmutable ? 'i-mdi-eye' : 'i-mdi-pencil',
        click: () => {
          slideoverOpenEdit({
            data: row,
            disabledFields: ['type'],
            onSubmit: async (event) => {
              await onSubmitUpdate(event.data)
              slideover.close()
              await refresh()
            },
          })
        },
      },
      {
        label: 'Duplicar',
        icon: 'i-mdi-content-duplicate',
        click: async () => {
          row.id = createId() // override id
          row.uid = createUidFulfillmentorders(row) // override uid
          await onSubmitCreate(row)
          await refresh()
        },
      },
      {
        label: 'Copiar ID',
        icon: 'i-mdi-content-copy',
        click: () => { copyToClipboard(row.id) },
      },
      {
        label: row.deletedAt ? 'Desarchivar' : 'Archivar',
        icon: row.deletedAt ? 'i-mdi-archive-arrow-up' : 'i-mdi-archive-arrow-down',
        click: async () => {
          const deletedAt = row?.deletedAt ? null : new Date().toISOString()
          await onSubmitUpdate({ ...row, deletedAt }) // soft delete
          await refresh()
        },
      },
    ],
    [
      {
        label: 'Abrir notas',
        icon: 'i-mdi-text',
        click: () => {
          const notes$ = useNotes()
          notes$.slideoverOpen({ data: { entityId: row.id, entity: 'fulfillmentorders', text: '' } })
        },
      },
    ],
    [
      {
        label: 'Ver archivos',
        icon: 'i-mdi-file-multiple',
        click: () => {
          slideover.open(FilesSlideover, { data: row, refresh, title: 'Ver archivos', utils })
        },
      },
    ],
    [
      {
        label: 'Copiar contraseña',
        icon: 'i-mdi-asterisk',
        disabled: !row.pdfSettings.password.enabled,
        click: () => copyToClipboard(row.pdfSettings.password.value),
      },
      {
        label: 'Ver PDF',
        icon: 'i-mdi-file-pdf',
        click: () => slideover.open(SlideoverPreview, {
          src: createUrl(`/api/blob/fulfillmentorders/${row.id}/fulfillmentorder.pdf`).href,
          title: `Orden de cumplimiento: ${row.uid}`,
        }),
      },
    ],
    ...dropdownItemsAction,
    [
      {
        label: 'Información',
        icon: 'i-mdi-information',
        click: () => { slideoverOpenInformation('fulfillmentorders', row) },
      },
      {
        label: row.$inmutable ? 'Desbloquear' : 'Bloquear',
        icon: row.$inmutable ? 'i-mdi-lock-open-variant' : 'i-mdi-lock',
        click: async () => {
          await onSubmitUpdate({ ...row, $inmutable: !row.$inmutable })
          await refresh()
        },
      },
    ],
    [
      {
        label: 'Eliminar',
        icon: 'i-mdi-delete',
        iconClass: 'bg-red-500',
        labelClass: 'text-red-500',
        click: () => {
          modalOpenDelete({
            onSubmit: async (event) => {
              await onSubmitDelete([row])
              modal.close()
              await refresh()
            },
          })
        },
      },
    ],
  ]

  // files:

  const { uploadFile, removeFile } = useApiBlob()

  const createPdf = (data: Fulfillmentorder, utils: FulfillmentordersUtils) => {
    const documentDefinition = createPdfmakeDocumentDefinitionFulfillmentorders(data, utils)
    const pdf = usePDFMake().createPdf(documentDefinition)
    return pdf
  }

  // updates the fulfillmentorder.files array (to be used after POST/PATCH)
  async function createFileFulfillmentorderPdf(data: Fulfillmentorder, utils: FulfillmentordersUtils) {
    const [existingData] = await $fetch<Fulfillmentorder[]>(`/api/db/fulfillmentorders`, {
      query: { id: data.id, $with: relationsFulfillmentorders },
    }) // findOne instead of get to allow passing query.$with
    const newData = { ...existingData, ...data }

    const pdf = createPdf(newData, utils)

    // IMPORTANT: wrap with a Promise to await the blob
    const blob: Blob = await new Promise(resolve => pdf.getBlob(blob => resolve(blob)))
    const file = new File([blob], 'fulfillmentorder.pdf', { type: 'application/pdf' })
    return uploadFile(file, { prefix: `fulfillmentorders/${data.id}` })
  }

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    createUidFulfillmentorders,
    getDropdownItems,
    createPdf,
    createFileFulfillmentorderPdf,
  }
}

export const useFulfillmentordersUtils = ({ $accounts, $branches, $contacts, $productionorders, $salesorders, $users }: {
  $accounts?: Awaited<ReturnType<typeof useFetch<Account[]>>>
  $branches?: Awaited<ReturnType<typeof useFetch<Branch[]>>>
  $contacts?: Awaited<ReturnType<typeof useFetch<Contact[]>>>
  $productionorders?: Awaited<ReturnType<typeof useFetch<Productionorder[]>>>
  $salesorders?: Awaited<ReturnType<typeof useFetch<Salesorder[]>>>
  $users?: Awaited<ReturnType<typeof useFetch<User[]>>>
} = {}) => {
  const options = {
    ...optionsFulfillmentorders,
    accountId: computed(() => $accounts?.data.value.map(toOption)),
    branchId: computed(() => $branches?.data.value.map(toOption)),
    contactId: computed(() => $contacts?.data.value.map(toOption)),
    productionorderId: computed(() => $productionorders?.data.value.map(toOption)),
    salesorderId: computed(() => $salesorders?.data.value.map(toOption)),
    userId: computed(() => $users?.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsFulfillmentorders.type, 'value'),
    stage: toMapByKey(optionsFulfillmentorders.stage, 'value'),
    status: toMapByKey(optionsFulfillmentorders.status, 'value'),
    delivery: {
      useAccountAddress: toMapByKey(optionsFulfillmentorders.delivery.useAccountAddress, 'value'),
      incoterm: toMapByKey(optionsFulfillmentorders.delivery.incoterm, 'value'),
    },
    productionorder: {
      stage: toMapByKey(optionsProductionorders.stage, 'value'),
      status: toMapByKey(optionsProductionorders.status, 'value'),
    },
    accountId: computed(() => toMapByKey($accounts?.data.value, 'id')),
    branchId: computed(() => toMapByKey($branches?.data.value, 'id')),
    contactId: computed(() => toMapByKey($contacts?.data.value, 'id')),
    productionorderId: computed(() => toMapByKey($productionorders?.data.value, 'id')),
    salesorderId: computed(() => toMapByKey($salesorders?.data.value, 'id')),
    userId: computed(() => toMapByKey($users?.data.value, 'id')),
    branchesByAccountId: computed(() => toMapByGroupKey($branches?.data.value, 'accountId')),
    contactsByAccountId: computed(() => toMapByGroupKey($contacts?.data.value, 'accountId')),
    productionordersByType: computed(() => toMapByGroupKey($productionorders?.data.value, 'type')),
    productionordersBySalesorderId: computed(() => toMapByGroupKey($productionorders?.data.value, 'salesorderId')),
  }

  return { options, maps }
}
