import { createId, type Options } from '#netzo/utils/app/index'
import { addMonths, format } from 'date-fns'
import { merge } from 'lodash-es'
import type { TDocumentDefinitions } from 'pdfmake/interfaces'

// copied from server/database/schema.ts

export const getDefaultProductionorder = (data: Partial<Productionorder>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)
  type: 'production',
  stage: 'new',
  status: 'pending',
  priority: '2',
  isDatetime: false,
  dateStart: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
  dateEnd: new Date(`${format(addMonths(new Date(), 1), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
  fileProductionorderPdf: [],
  tags: [],
  files: [],
  pdfSettings: merge(defaultPdfSettings, { watermark: { text: 'LATINTA' } }),
}, data)

export const optionsProductionorders = {
  type: [
    { value: 'internal', label: 'Interna', description: 'Órdenes de trabajo para procesos internos que no están vinculadas a ventas.', icon: 'i-mdi-factory', color: 'blue' },
    { value: 'external', label: 'Externa', description: 'Órdenes originadas de solicitudes de clientes para productos.', icon: 'i-mdi-package-variant', color: 'green' },
  ],
  stage: [
    { value: 'new', label: 'Nueva', description: 'La orden de producción ha sido creado pero no ha sido programado o asignado.', icon: 'i-mdi-plus-circle-outline', color: 'gray' },
    { value: 'scheduled', label: 'Programada', description: 'El trabajo ha sido programado con fechas para su ejecución.', icon: 'i-mdi-calendar', color: 'blue' },
    { value: 'in-progress', label: 'En Progreso', description: 'El trabajo está en curso y se está realizando activamente.', icon: 'i-mdi-progress-clock', color: 'amber' },
    { value: 'quality-assurance', label: 'Control de Calidad', description: 'El trabajo está en proceso de inspección para asegurar el cumplimiento de estándares de calidad.', icon: 'i-mdi-check-decagram', color: 'purple' },
    { value: 'reworking', label: 'Retrabajo', description: 'El trabajo no ha pasado el control de calidad y se está rehaciendo.', icon: 'i-mdi-replay', color: 'red' },
    { value: 'completed', label: 'Completada', description: 'El trabajo ha sido terminado y todas las tareas se han completado.', icon: 'i-mdi-check-circle', color: 'green' },
    { value: 'canceled', label: 'Cancelada', description: 'El trabajo ha sido cancelado y no se llevará a cabo.', icon: 'i-mdi-cancel', color: 'red' },
    { value: 'closed', label: 'Cerrada', description: 'La orden de producción ha sido completamente finalizada y archivada.', icon: 'i-mdi-lock', color: 'grey' },
  ],
  status: optionsSharedApp.statusProcess,
  priority: optionsSharedApp.priority,
} satisfies Options

export const relationsProductionorders: Record<string, boolean> = {
  fulfillmentorders: true,
  productionorderitems: true,
  salesorder: true,
  user: true,
}

export const productionorderToViewCalendarEvent = (calendarId: keyof Productionorder, utils: ProductionordersUtils) => {
  return (row: Productionorder): CalendarEvent => {
    const calendarName = utils.maps[calendarId].get(row[calendarId])?.label?.toUpperCase()
    const dateFormat = row.isDatetime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'
    return {
      ...row,
      id: row.id,
      title: `[${calendarName}] ${row.uid}`,
      description: row.description!,
      start: format(new Date(row.dateStart), dateFormat),
      end: format(new Date(row.dateEnd), dateFormat),
      calendarId: row[calendarId],
    }
  }
}

export const createPdfmakeDocumentDefinitionProductionorders = async (
  productionorder: Productionorder,
  utils: ProductionordersUtils,
): Promise<TDocumentDefinitions> => {
  // see https://pdfmake.github.io/docs/0.1/document-definition-object/images/
  return {
    language: 'es-ES',
    info: {
      subject: 'Orden de Venta Esgraf',
      keywords: 'diseño, agencia, publicidad',
      author: 'ESGRAF',
      producer: 'Netzo (https://netzo.dev)',
    },
    footer: function (currentPage, pageSize) {
      return {
        stack: [
          {
            text: `Página ${currentPage.toString()} de ${pageSize}`,
          },
          {
            text: [
              {
                text: 'Generado con ',
              },
              {
                text: 'netzo.dev',
                link: 'https://netzo.dev',
                color: 'blue',
              },
            ],
          },
        ],
        alignment: 'center',
      }
    },
    styles: {
      header: {
        fontSize: 10,
        bold: true,
        margin: [0, 0, 10, 5],
      },

    },
    defaultStyle: {
      fontSize: 8,
    },
    pageSize: 'LETTER',
    pageMargins: [20, 20, 20, 40],
  }
}
