<script setup lang="ts">
const model = defineModel<Address>({ required: true })

const props = defineProps<{
  label?: string
  required?: boolean
  disabled?: boolean
  cols?: number
}>()

const FIELDSET = `w-full grid grid-cols-1 md:grid-cols-2 gap-2 !mt-4 !mb-2 p-4 border rounded-lg`
</script>

<template>
  <fieldset :class="FIELDSET" :disabled="disabled">
    <UFormGroup
      :label=" props.label ?? 'Dirección'"
      :class="`col-span-${props.cols ?? 2}`"
    />
    <UFormGroup
      label="Calle"
      name="address.street"
    >
      <UInput
        v-model="model.street"
        :disabled="disabled"
      />
    </UFormGroup>

    <UFormGroup
      label="Número Exterior"
      name="address.numberExterior"
    >
      <UInput
        v-model="model.numberExterior"
        :disabled="disabled"
      />
    </UFormGroup>

    <UFormGroup
      label="Número Interior"
      name="address.numberInterior"
    >
      <UInput
        v-model="model.numberInterior"
        :disabled="disabled"
      />
    </UFormGroup>

    <UFormGroup
      label="Colonia"
      name="address.neighborhood"
    >
      <UInput
        v-model="model.neighborhood"
        :disabled="disabled"
      />
    </UFormGroup>

    <UFormGroup
      label="Ciudad"
      name="address.city"
    >
      <UInput
        v-model="model.city"
        :disabled="disabled"
      />
    </UFormGroup>

    <UFormGroup
      label="Delegación/Municipio"
      name="address.district"
    >
      <UInput
        v-model="model.district"
        :disabled="disabled"
      />
    </UFormGroup>

    <UFormGroup
      label="Estado"
      name="address.state"
    >
      <UInput
        v-model="model.state"
        :disabled="disabled"
      />
    </UFormGroup>

    <UFormGroup
      label="País"
      name="address.country"
    >
      <SelectMenuBase
        v-model="model.country"
        :options="optionsShared.countryCode"
        value-attribute="label"
        :disabled="disabled"
      />
    </UFormGroup>

    <UFormGroup
      label="Código Postal"
      name="address.postalCode"
    >
      <UInput
        v-model="model.postalCode"
        :disabled="disabled"
      />
    </UFormGroup>
  </fieldset>
</template>
