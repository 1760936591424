import { createId, type Options } from '#netzo/utils/app/index'
import { merge } from 'lodash-es'

export const getDefaultContact = (data: Partial<Contact>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)
  type: 'customer',
  status: 'active',
  emailOptIn: true,
  tags: [],
  files: [],
}, data)

export const optionsContacts = {
  type: [
    { value: 'customer', label: 'Cliente', description: 'Contactos de empresas a las que vendemos nuestros productos o servicios.', color: 'green' },
    { value: 'partner', label: 'Partner', description: 'Contactos de empresas con las que tenemos una relación de colaboración. Pueden ser socios comerciales, proveedores de servicios, distribuidores, etc.', color: 'red' },
    { value: 'supplier', label: 'Proveedor', description: 'Contactos de empresas que nos suministran productos o servicios.', color: 'orange' },
    { value: 'other', label: 'Otro', description: 'Cualquier tipo de contacto que no encaje en las categorías anteriores.', color: 'gray' },
  ],
  status: optionsSharedApp.statusResource,
  emailOptIn: [
    { value: 'true', label: 'Si', description: 'El contacto acepta recibir correos electrónicos.', icon: 'i-mdi-email', color: 'green' },
    { value: 'false', label: 'No', description: 'El contacto no desea recibir correos electrónicos.', icon: 'i-mdi-email-off', color: 'red' },
  ],
} satisfies Options

export const relationsContacts: Record<string, boolean> = {
  account: true,
  branch: true,
  inquiries: true,
  user: true,
}
